.App {
  text-align: center;
}
input {
  border: 2px solid #aaa3;
  width: 200px;
  height: 20px;
  /* box-shadow: 6px 4px 0px #3af, -6px -4px 0px #69ba46; */
}
label {
  display: block;
}

.symbols_input {
  margin: 0 auto;
  max-width: 500px;
}

.App-header {
  background-color: #282c34;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.submit_button {
  background: #69ba46;
  color: #fff;
  font-size: 16px;
  height: 32px;
  border-radius: 8px;
  margin: 16px;
}

input[type="submit"]:hover {
  background: #79ca66;
}

.submit_container {
  display: block;
}

.body {
  min-height: 700px;
}